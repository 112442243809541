import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore, persistReducer } from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import localforage from 'localforage';
import { authSlice } from './slices';
import { articleApi, authApi, categorieApi, sousCategorieApi, utilisateurApi, affectationApi } from './services';

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [categorieApi.reducerPath]: categorieApi.reducer,
  [sousCategorieApi.reducerPath]: sousCategorieApi.reducer,
  [articleApi.reducerPath]: articleApi.reducer,
  [utilisateurApi.reducerPath]: utilisateurApi.reducer,
  [affectationApi.reducerPath]: affectationApi.reducer,
  auth: authSlice.reducer,
});

const storeMiddleware = [
  authApi.middleware,
  articleApi.middleware,
  categorieApi.middleware,
  sousCategorieApi.middleware,
  utilisateurApi.middleware,
  affectationApi.middleware,
];

const persistConfig = {
  key: 'fyleclinique-bo-store',
  version: 1,
  storage: localforage,
  blacklist: [
    articleApi.reducer,
    sousCategorieApi.reducer,
    categorieApi.reducer,
    authApi.reducer,
    affectationApi.reducer,
  ],
  transforms: [
    encryptTransform({
      secretKey: 'wazadzkeahevezdfeziyfau87672E',
      onError(error) {
        console.log(error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const fylecliniqueBoStore = configureStore({
  devTools: process.env === 'developement',
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(storeMiddleware),
});
const persistor = persistStore(fylecliniqueBoStore);

setupListeners(fylecliniqueBoStore.dispatch);
export { fylecliniqueBoStore, persistor };
